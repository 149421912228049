$font_color: #2a2a2a;
$blue300: #323f54;
$blue200: #365487;
$blue100: #255bb9;
$blue80: #0075c1;
$blue50: #008cd6;
$blue40: #6484f2;
$blue30: #97b5ea;
$blue10: #f9fdff;
$black100: #333d4b;
$gray300: #757575;
$gray200: #999;
$gray100: #c8c8c8;
$gray50: #e5e5e5;
$gray30: #f1f1f1;
$gray20: #f5f5f5;
$gray10: #f8f8f8;
$white: #fff;
$green100: rgb(46 206 72);
$red100: #ff4949;
$red50: #ffecec;
$orange100: #ffb049;
$pink100: #ff819f;
