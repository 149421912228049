/* stylelint-disable keyframes-name-pattern */

@keyframes slideLeft {
  from {
    transform: translateX(3.5%);
    opacity: 0.5;
  }

  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}
