/* stylelint-disable selector-id-pattern */

/* katex CSS */

.katex-error {
  color: #212121 !important;
}

#katex,
#katex_list {
  font: normal 0.75rem 'KaTeX_Main', 'Times New Roman', serif;
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

/* katex가 필요한 부분 적용 */
.mop,
.mord,
.mopen,
.mclose {
  font: normal 1rem 'KaTeX_Main', 'Times New Roman', serif;
  line-height: 2;
}

.mord.mtight {
  font-size: 0.75rem;
}

/* 띄어쓰기 padding으로 표시 */
.mspace {
  padding: 0 1px;
}

/* 한글 부분은 katex가 나오지 않도록 */
.hangul_fallback,
.mord.text {
  font: inherit;
}

/* 대기환경 등 특정 문제에서 카텍스 폰트가 한글에 적용됨. */
/* 한글부분 katex가 나오지않도록  */
.base > .mord,
.base > .mord > .mord {
  font: inherit;
}

#katex_list img {
  display: none;
}

.katex > .katex-html {
  text-align: left;
}

.katex .base {
  /* base 태그를 신경쓰지 않도록 display : contents 적용 */
  display: contents;
  white-space: pre-wrap;
  width: 100%;
}

/* cbt 모의고사, cbt review 해설*/
/* 문제 줄바꿈 이슈 픽스 */
.cbt-katex .base {
  white-space: normal !important;
}
.cbt-katex .katex .base {
  display: inline;
}
/* 폰트 사이즈 상속 안되는 문제 */
.cbt-katex .katex {
  font-size: inherit;
}

.katex-display > .katex {
  font-size: inherit;
}

.katex .mclose,
.mord {
  /* 특수문자 혹은 특정태그 사이즈 상속이 안되는 문제. */
  font-size: inherit;
}

.katex img {
  height: 100% !important;
  width: 100% !important;
  /*max-width: 500px;
  max-height: 400px;*/
  margin: 0 auto;
  object-fit: contain;
}

/* #katex img:hover {
  height: 100% !important;
  width: 100% !important;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
} */

.cbt-image-half-width img {
  height: 50% !important;
  width: 50% !important;
}

.cbt-image-half-width-always img {
  height: 50% !important;
  width: 50% !important;
}

.cbt-image-three-quarter-width img {
  height: 75% !important;
  width: 75% !important;
}

@media only screen and (width <= 1024px) {
  .cbt-image-half-width img {
    height: 100% !important;
    width: 100% !important;
  }
}

@media only screen and (width <= 768px) {
  .katex .vlist {
    font-size: 0.625rem;
  }
}

/* katex CSS */
