@import '/utils/utils';

.errorBoundaryRoot {
  position: absolute;
  left: 35%;
  width: 30%;
  top: 25%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include mobile {
    width: 80%;
    left: 10%;
  }
  @include tablet {
    width: 70%;
    left: 15%;
  }
  @include tablet_landscape {
    width: 60%;
    left: 20%;
  }

  img {
    width: 52px;
    height: 52px;
    margin-bottom: 20px;
  }

  .title {
    @include desktop_title_02;

    margin: 0.75rem 0;
  }

  .logo{
    width: 200px;
    height: auto;
    >a{
      display: block;
      width: 100%;
      height: 100%;
  > img{
    width: 100%;
    height: auto;
    margin: 0;
  }
    }
  }

  .content {
    @include desktop_body_04;

    text-align: center;
    display: flex;
    flex-direction: column;

    &_error {
      @include desktop_body_03;

      color: $red100;
      text-align: center;
    }
  }

  .refreshBtn {
    cursor: pointer;
    margin-top: 100px;
    border: none;
    background: $blue100;
    width: 80%;
    font-size: 20px;
    padding: 13px 0;
    color: white;
    @include mobile {
      width: 90%;
    }
    @include tablet {
      width: 60%;
    }
    @include tablet_landscape {
      width: 50%;
    }
  }
}
