.paused {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    background: white var(--after-logo);
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: center;
    inset: 0;
    z-index: 999;
  }
}
