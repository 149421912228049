/* stylelint-disable custom-property-pattern */
* {
  font-family: var(--font-NotoSansKR), var(--font-NanumGothic), 'Noto Sans KR', -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif, 'Nanum Gothic';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*[tabindex]:focus-visible {
  outline: 2px #1c315e solid;
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  position: relative;
  margin: 0;
  background: white !important;
  min-height: 100vh;
  overflow-x: hidden;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  text-align: center;
  background: none;
  text-decoration: none;
  outline: none;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.no-scroll {
  overflow: hidden;
}

/* React Quill */
.ql-editor {
  min-height: 500px !important;
}
