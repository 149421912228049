@import './color';
@import './typography';
@import './animation';

@mixin mobile {
  @media (min-width: $breakpoint-mobile) and (max-width: ($breakpoint-tablet - 1px)) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-tablet) and (max-width: ($breakpoint-tablet_landscape - 1px)) {
    @content;
  }
}

@mixin tablet_landscape {
  @media (min-width: $breakpoint-tablet_landscape) and (max-width: ($breakpoint-desktop - 1px)) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

$breakpoint-mobile: 0; /* 모바일 */
$breakpoint-tablet: 768px; /* 태블릿 */
$breakpoint-tablet_landscape: 1024px; /* 태블릿 가로 */
$breakpoint-desktop: 1280px; /* 데스크탑 */

/* 반응형 View */
.display {
  &_mobile {
    display: none;
    @include tablet_landscape {
      display: block;
    }
    @include tablet {
      display: block;
    }
    @include mobile {
      display: block;
    }
  }

  &_desktop {
    display: none;
    @include desktop {
      display: block;
    }
  }
}


$primary: #255bb9;
$grey_dark: #c8c8c8;
$grey_medium: #f5f5f5;
$grey_white: #f9f9f9;
