/* 메인페이지 carousel style */
.slick-dots.slick-thumb {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.slick-dots.slick-thumb li {
  width: 15px;
  height: 5px;
  overflow: hidden;
  margin: 0 3px;
  transition: all 0.5s ease-in-out;
  will-change: width;
  background-color: #e5e5e5;
}

.slick-dots.slick-thumb li.slick-active {
  background-color: #255bb9;
  width: 24px;
}

.dot {
  background: none;
  height: 5px;
}
