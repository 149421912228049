@import '/utils/utils';

.toast {
  @include desktop_body_03;

  @include mobile {
    @include mobile_body_01;
  }

  position: fixed;
  left: 50%;
  top: 5%;
  animation: fadeIn 0.35s ease-in-out forwards;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  padding: 13px 70px;
  z-index: 20000;
  white-space: nowrap;

  &_type {
    &_success {
      background-color: $black100;
      color: $white;
    }

    &_error {
      background-color: $red100;
      color: $white;
    }

    &_warn {
      background-color: $orange100;
      color: $black100;
    }
  }

  &_fadeout {
    animation: fadeOut 0.35s ease-in-out forwards;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -100%);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(-50%, -50%);
  }

  to {
    opacity: 0;
    transform: translate(-50%, -100%);
  }
}
